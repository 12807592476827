import React from 'react';
import styled from 'styled-components';
import { IWebBannerList } from '../../../store/utils/bannerType';

interface SubBannerProps {
  data: IWebBannerList[];
}

const SubBanner: React.FC<SubBannerProps> = ({ data }) => {
  const navigateToExternalLink = (url: string) => {
    if (url !== '') {
      const completeUrl = url;
      window.open(completeUrl, '_blank');
    }
  };

  // Sort the data based on orderNum
  const sortedData = data.sort((a, b) => a.orderNum - b.orderNum);

  return (
    <SubWrapper>
      {sortedData.map((item) => {
        return (
          <SubImg
            onClick={() => navigateToExternalLink(item.webLink)}
            key={item.orderNum}
          >
            <img src={`https://${item.imageUrl}`} alt="main" />
          </SubImg>
        );
      })}
    </SubWrapper>
  );
};
export default SubBanner;

const SubWrapper = styled.div`
  width: 43%;
  height: 100vh;
  overflow: auto;
`;
const SubImg = styled.div`
  width: 100%;
  height: calc(100% / 3);
  padding: 0;
  margin: 0;
  cursor: pointer;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
