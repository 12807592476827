import React, { useEffect, useState } from 'react';
import { fetchWebView } from '../../store/api';
import MainBanner from './mainBanner';
import SubBanner from './subBanner';
import { IWebBannerList } from '../../store/utils/bannerType';

function Banner() {
  const [mainData, setMainData] = useState<IWebBannerList[]>([]);
  const [subData, setSubData] = useState<IWebBannerList[]>([]);
  

  useEffect(() => {
 
    // url 파싱
    const fetchData = async () => {
      try {
        const response = await fetchWebView();
        console.log(response,"response");
        setMainData(
          response.filter((item: IWebBannerList) => item.type === 'main')
        );
        setSubData(
          response.filter((item: IWebBannerList) => item.type === 'sub')
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <MainBanner data={mainData} />
      <SubBanner data={subData} />
    </div>
  );
}

export default Banner;
