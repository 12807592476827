import React, { useMemo } from 'react';
import styled from 'styled-components';
import Slider, { Settings } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SlideWrapper = styled.section`
  width: 100%;
  height: 100%;
  .slick-list {
    img {
      height: 100vh;
      object-fit: cover;
    }
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-dots {
    bottom: 0;
  }
  .slick-dots li {
    width: 40px;
  }
  .slick-dots li button {
    width: 40px;
    height: 10px;
    border-radius: 25px;
    background-color: #fff;
    opacity: 0.3;
    &::before {
      content: '';
    }
  }
  .slick-dots .slick-active button {
    opacity: 1;
  }
`;

interface sliderProps {
  /** 슬라이더 아이템 요소 */
  children: React.ReactNode;
  /** 커스텀 클래스 */
  className?: string;
  /** 자동재생 (속도 설정시 number 타입으로) */
  autoplay?: boolean | number;
  /** 슬라이더 속도 */
  speed?: number;
  /** 반복 여부 */
  loop?: boolean;
}

function Slick({
  children,
  className,
  autoplay = true,
  speed = 1000,
  loop = true,
}: sliderProps) {
  const settings = useMemo<Settings>(
    () => ({
      dots: true,
      infinite: loop,
      speed: speed,
      slidesToShow: 1,
      autoplay: Boolean(autoplay),
      autoplaySpeed: typeof autoplay === 'boolean' ? 5000 : autoplay,
    }),
    [autoplay, loop, speed]
  );
  return (
    <SlideWrapper className={className}>
      <Slider {...settings}>{children}</Slider>
    </SlideWrapper>
  );
}

export default Slick;
