import axios from 'axios';
import { apiEndpoint } from './endPoint';

// TODO: dev - dev QA - stg 나머지 prod
export const fetchWebView = async () => {
  const { data } = await axios.get(
    `${apiEndpoint()}/notice/web/banner/preview`,
   
  );
  if (data.code === 2000 && data.httpStatusCode === 200) {
    return data.result;
  }
};

export const fetchWebNotice = async (params: {
  server: string | null;
  country: string | null;
}) => {
  const { data } = await axios.get(
    `${apiEndpoint()}/notice/web/current?sort=orderNum:desc`,
    {
      params: params,
    }
  );
  if (data.code === 2000 && data.httpStatusCode === 200) {
    return data.result;
  }
};
