import { createBrowserRouter } from 'react-router-dom';
import Banner from './components/banner';

const Routes = createBrowserRouter([
  {
    path: '/banner',
    element: <Banner />,
  },
  {
    path: '/',
    element: <Banner />,
  },
]);

export default Routes;
