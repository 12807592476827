import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import '../swiper.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slick from '../../../store/utils/slick';
import { IWebBannerList } from '../../../store/utils/bannerType';

interface MainBannerProps {
  data: IWebBannerList[];
}

const MainBanner: React.FC<MainBannerProps> = ({ data }) => {
  const [isDragging, setIsDragging] = useState(false);
  const dragStartPos = useRef(0);

  const handleMouseDown = (event: React.MouseEvent) => {
    setIsDragging(false);
    dragStartPos.current = event.clientX;
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (Math.abs(event.clientX - dragStartPos.current) > 5) {
      setIsDragging(true);
    }
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    setIsDragging(false);
    dragStartPos.current = event.touches[0].clientX;
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    if (Math.abs(event.touches[0].clientX - dragStartPos.current) > 5) {
      setIsDragging(true);
    }
  };

  const navigateToExternalLink = (url: string) => {
    if (!isDragging && url !== '') {
      const completeUrl = url;

      window.open(completeUrl, '_blank');
    }
  };

  const sortedData = data.sort((a, b) => a.orderNum - b.orderNum);

  return (
    <MainWrapper>
      <Slick className="slick">
        {sortedData &&
          sortedData.map((item) => (
            <SliderItem
              className="swiper"
              key={item.id}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onClick={() => navigateToExternalLink(item.webLink)}
            >
              <img src={`https://${item.imageUrl}`} alt="main" />
            </SliderItem>
          ))}
      </Slick>
    </MainWrapper>
  );
};
export default MainBanner;

const MainWrapper = styled.div`
  width: 57%;
  height: 100vh;
  overflow: hidden;
`;
const SliderItem = styled.div`
  width: 100%;
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;
